
































import { Component, Vue } from 'vue-property-decorator';
import { IUDocumentosCategorias } from '@/interfaces';
import {
  dispatchGetDocumentosCategorias,
  dispatchUpdateCategoria,
} from '@/store/admin/actions';
import { readAdminOneCategoria } from '@/store/admin/getters';

@Component
export default class EditDocumento extends Vue {
  public valid = true;
  public idCategoria: number = 0;
  public categoriaNombre: string = '';
  public categoriaAlias: string = '';

  public async mounted() {
    await dispatchGetDocumentosCategorias(this.$store);

    this.reset();
  }

  public reset() {
    if (this.categoria) {
      this.categoriaNombre = this.categoria.categoria;
      this.categoriaAlias = this.categoria.alias;

      this.idCategoria = this.categoria.id;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.categoriaNombre) {
        const updatedProfile: IUDocumentosCategorias = {
          alias: this.categoriaAlias,
          categoria: this.categoriaNombre,
          id: this.idCategoria,
        };
        await dispatchUpdateCategoria(this.$store, {
          id: updatedProfile.id,
          categoria: updatedProfile.categoria,
          alias: updatedProfile.alias,
        });
        this.$router.push('/main/admin/documentos/categorias');
      }
    }
  }

  get categoria() {
    return readAdminOneCategoria(this.$store)(
      +this.$router.currentRoute.params.id,
    );
  }
}
